<template>
  <div class="subject">
    <div v-if="subjectsList.length > 0">
        <b-row>
        <b-col lg="3" md="6" v-for="subItem in paginationList" :key="subItem.id">
            <MainCard
              type="subject"
              :count1="subItem.studentsCount"
              :count2="subItem.unitsCount"
              :date="subItem.dateCreated"
              :name="subItem.name "
              :classes="subItem.className"
              :label="
                  educationLevelList.find((item) => item.id == subItem.educationalLevel).name
              "
              :media=" subItem.url "
              :icon=" subItem.iconUrl "
              @details="goToDetails(subItem.id)"
              @delete="deleteSub(subItem.id)"
            ></MainCard>
        </b-col>
        <b-col cols="12" class="d-flex justify-content-center">
                <ek-pagination
                    :items="subjectsList"
                    v-model="paginationList"
                    :pageLength="8"
                ></ek-pagination>
        </b-col>
        </b-row>
    </div>
     <div v-else class="text-center mt-2">
        <h4>لا يوجد مواد</h4>
    </div>
  </div>
</template>
<script>
import MainCard from "@/app/admin/components/ElearningCard";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  components: { MainCard },
  data() {
        return {
            paginationList: []
        };
    },

  computed: {
    ...mapState({
      subjects: (state) => state.subjects.subjects,
      educationLevelList: (state) => state.subjects.educationLevelList,
    }),
    ...mapGetters(["subjectsList"]),
  },
  methods: {
    goToDetails(id) {
      this.$router.push({ path: `/admin/subjects/${id}` });
    },
    ...mapActions(["getSubjectsList", "deleteSubject"]),
    deleteSub(id) {
        this.deleteSubject(id);
    }
  },
  created() {
    this.getSubjectsList();
  },
  beforeDestroy() {
    this.$store.commit("Set_Subjects_Dto");
  }
};
</script>

<style lang="scss">

@import "/src/@core/scss/core.scss";
.subject {
  img {
    width: 100%;
    border-radius: 10px;
  }
  .unicon.button {
    svg {
      fill: $main-color !important ;
    }
  }
  svg {
    fill: #6e6b7b;
  }
  .card-footer {
    text-align: center;
    cursor: pointer;
    button {
      color: #fff !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
}
</style>
