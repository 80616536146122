<template>
    <div class="mt-1 counters justify-content-between">
        <div class="d-flex align-items-center counter-1">
            <unicon :name="type.icon1"></unicon>
            <div class="d-flex">
                <span> {{ type.count1 }} </span>
                <span>({{ val1 }})</span>
            </div>
        </div>

        <span>|</span>

        <div class="d-flex align-items-center counter-2">
            <unicon :name="type.icon2" class="mx-50"></unicon>
            <div class="d-flex">
                <span> {{ type.count2 }} </span>
                <span>({{ val2 }})</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["type", "val2", "val1"]
};
</script>

<style lang="scss">
.counters {
    display: flex;
    .counter-1,
    .counter-2 {
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
}
</style>
