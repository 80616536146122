<template>
    <b-card footer-class="p-0" body-class="p-1" header-class="p-1 pb-50">
        <template #header>
            <slot>
                <div
                    class="w-100 d-flex justify-content-between align-items-center"
                >
                    <div>
                        <div class="d-flex">
                            <slot class="iconSub" name="icon" v-if="type == 'subject'">
                                <img :src="icon" alt="" style="border-radius: 50%;
                                                                width: 30px;
                                                                height: 30px;
                                                                margin-left:12px" />
                            </slot>
                            <h3>{{ name }}</h3>
                        </div>
                        <div class="d-flex">
                            <h6>
                                {{
                                    type != "ad"
                                        ? label
                                        : new Date(
                                              startDate
                                          ).toLocaleDateString()
                                }}
                            </h6>
                            <span> _ </span>
                            <h6>
                                {{
                                    type != "ad"
                                        ? classes
                                        : new Date(endDate).toLocaleDateString()
                                }}
                            </h6>
                        </div>
                    </div>
                    <div >
                        <b-dropdown
                            size="md"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                        >
                            <template
                                #button-content
                                class="p-0 pb-2"
                                @click="$emit('options', id)"
                            >
                                <unicon
                                    name="ellipsis-v"
                                    class="button"
                                ></unicon>
                            </template>
                            <b-dropdown-item
                                @click="deleteBtn"
                                class="svg-class"
                            >
                                <span>حذف</span>
                                <unicon class="ml-3" name="trash"></unicon>
                            </b-dropdown-item>
                            <b-dropdown-item @click="editBtn" v-if="type != 'subject' && type != 'ad'">
                                <span>تعديل</span>
                                <unicon class="ml-3" name="pen"></unicon>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </slot>
        </template>

        <slot name="media" v-if="type != 'class'">
            <img :src="media" style="width: 100%;
                                     height: 290px;
                                     object-fit: cover;" />
        </slot>

        <slot name="card-content">
            <Counters
                :type="currentType"
                :val1="count1"
                :val2="count2"
                v-if="type != 'ad'"
            />
            <div class="mt-1 d-flex" v-if="type != 'ad'">
                <span>تاريخ الإضافة</span>
                <span class="mx-50">{{
                    new Date(date).toLocaleDateString()
                }}</span>
            </div>
            <!-- <div class="mt-1 d-flex" v-if="type == 'ad'">
                <span class="mx-50">{{
                    new Date(startDate).toLocaleDateString()
                }}</span>
            </div> -->
            <div v-else class="d-flex h-100 align-items-center">
                <p class="my-1">{{ description.substring(0, 25) }}...</p>
            </div>
        </slot>

        <template #footer>
            <b-button
                variant="main-color"
                @click="classDetail"
                class="w-100 p-1"
                >عرض التفاصيل</b-button
            >
        </template>
    </b-card>
</template>

<script>
import Counters from "./Counters.vue";
export default {
    components: { Counters },
    props: {
        dto: Object,
        name: { type: String, default: "name" },
        label: { type: String, default: "label" },
        date: { type: String },
        startDate: { type: String },
        endDate: { type: String },
        description: { type: String },
        classes: { type: String, default: "classes" },
        count1: { type: Number },
        count2: { type: Number },
        id: { type: String, default: "" },
        type: String,
        media: {
            default:
                "https://media.istockphoto.com/photos/back-view-of-elementary-students-raising-their-arms-on-a-class-picture-id1000887536?k=20&m=1000887536&s=612x612&w=0&h=79B-yK_-GFRauGUW-Hc4LbxHT0KOzxK5PU2fEA5JT0I=",
            type: String
        },
        icon: {
            default:
                "https://media.istockphoto.com/photos/back-view-of-elementary-students-raising-their-arms-on-a-class-picture-id1000887536?k=20&m=1000887536&s=612x612&w=0&h=79B-yK_-GFRauGUW-Hc4LbxHT0KOzxK5PU2fEA5JT0I=",
            type: String
        }
    },
    data() {
        return {
            types: [
                {
                    type: "class",
                    icon1: "users-alt",
                    icon2: "books",
                    count1: "عدد الطلاب  ",
                    count2: "عدد المواد"
                },
                {
                    type: "subject",
                    icon1: "users-alt",
                    icon2: "books",
                    count1: "عدد الطلاب  ",
                    count2: "عدد الوحدات"
                },
                {
                    type: "unit",
                    icon1: "wallet",
                    icon2: "books",
                    count1: "الاشتراكات  ",
                    count2: "الدروس"
                },
                {
                    type: "ad"
                }
            ]
        };
    },
    computed: {
        currentType() {
            return this.types.find(el => el.type == this.type);
        }
    },
    methods: {
        classDetail() {
            this.$emit("details", this.id);
        },
        deleteBtn() {
            this.$emit("delete", this.id);
        },
        editBtn() {
            this.$emit("edit", this.id);
        }
    }
};
</script>